import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { Route } from '@angular/router';
import { INavbarRoute } from '../../layout/navbar/INavbarRoute';

import { Tool } from '../../model/auth/login/Tool';
import { FemcareCatalogComponent } from '../../product/femcare/FemcareCatalog.component';
import { FemcareCatalogSolrcoreMappingComponent } from '../../product/femcare/mapping/FemcareCatalogSolrcoreMapping.component';
import { FemcareSitesComponent } from '../../product/femcare/FemcareSites.component';
import { FemcareSolrComponent } from '../../product/femcare/FemcareSolr.component';
import { ProductImporterComponent } from '../../productImporter/ProductImporter.component';
import { ImportLogsComponent } from '../../product/importLog/ImportLogs.component';

export const FemCareChildRoutes: (INavbarRoute | Route)[] = [
    {
        navbarName: 'Catalogs',
        path: 'catalogs',
        component: FemcareCatalogComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592061/4.1.1+Pim+FemCare+Catalogs',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Catalog ⬌ Solr',
        path: 'mapping/catalog-solr',
        component: FemcareCatalogSolrcoreMappingComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592226/4.4+Pim+Mappings',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Sites',
        path: 'sites',
        component: FemcareSitesComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107562300/4.1.2+Pim+FemCare+Sites',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Solr',
        path: 'solr',
        component: FemcareSolrComponent,
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107592146/4.1.3+Pim+FemCare+Solr+Cores',
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    },
    {
        navbarName: 'Product Importer',
        path: 'product-importer',
        component: ProductImporterComponent,
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/171540819/7.4+Femcare+Product+Importer+How+To+s',
        requiredAccess: {
            tool: [Tool.ProductImporter]
        }
    },
    {
        navbarName: 'Product Import Logs',
        path: 'product-import-logs',
        component: ImportLogsComponent,
        requiredAccess: {
            tool: [Tool.ProductConfiguration]
        }
    }
];
