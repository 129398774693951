import { Component, Input } from '@angular/core';

import { BaseCrud } from 'tridion.web.ui/src/app/angular/crud/BaseCrud';
import { CrudMode } from 'tridion.web.ui/src/app/angular/crud/CrudMode';
import { ICrudData } from 'tridion.web.ui/src/app/angular/crud/ICrudData';

@Component({
    selector: 'admin-crud-actions',
    templateUrl: 'CrudActions.component.html'
})
export class CrudActionsComponent<I, D extends ICrudData<I>> {
    @Input()
    item: BaseCrud<I, D>;
    @Input()
    itemType?: string;
    @Input()
    disableSubmit?: boolean;
    @Input()
    changedOrder? = false;

    // FIXME: Unit test.
    cancel() {
        var item = this.item;

        if (item.isNew()) {
            item.onDelete.emit();
        } else {
            item.setMode(CrudMode.Read);
        }
    }

    getCancelText() {
        // console.log('getCancelText');
        var text;

        if (this.item.isSaved()) {
            text = 'Done';
        } else {
            text = 'Nevermind';
        }

        return text;
    }

    getErrorText() {
        // console.log('getErrorText');
        var text = 'There was an error saving the ';

        var type = this.itemType;
        if (type) {
            text += type.toLowerCase();
        } else {
            text += 'item';
        }

        text += '.';

        return text;
    }

    getSaveText() {
        // console.log('getSaveText');
        var text;

        if (this.item.isNew()) {
            text = 'Add';

            var type = this.itemType;
            if (type) {
                text += ' ' + type;
            }
        } else {
            text = 'Save';
        }

        // console.log(text);

        return text;
    }

    getSavingText() {
        // console.log('getSavingText');
        var text;

        if (this.item.isNew()) {
            text = 'Adding';

            var type = this.itemType;
            if (type) {
                text += ' ' + type;
            }
        } else {
            text = 'Saving';
        }

        return text;
    }
}
