import { Inject, Injectable } from '@angular/core';
import { IJobstProduct } from '../../../../model/product/tena/jobst/IJobstProduct';
import { BaseAuthorizedGuidCrudApi } from '../../../BaseAuthorizedGuidCrudApi';
import { Observable, of } from 'rxjs';
import { LiveStageService } from '../../../../environment/LiveStage.service';
import { ApiErrorHandler } from 'tridion.web.ui/src/app/angular/api/error/ApiErrorHandler';
import { AuthService } from 'tridion.web.ui/src/app/angular/login/adal/Auth.service';
import { ENVIRONMENT } from 'tridion.web.ui/src/app/angular/environment/ENVIRONMENT';
import { Http } from '@angular/http';
import { IEnvironment } from '../../../../environment/IEnvironment';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class JobstProductImporterFormApi extends BaseAuthorizedGuidCrudApi<IJobstProduct> {
    private _selectedSiteId: string;

    set selectedSiteId(selectedSiteId: string) {
        this._selectedSiteId = selectedSiteId;
    }

    constructor(
        apiErrorHandler: ApiErrorHandler,
        authService: AuthService,
        @Inject(ENVIRONMENT) environment: IEnvironment,
        http: Http,
        private liveStageService: LiveStageService
    ) {
        super(apiErrorHandler, authService, environment, http);
    }

    inactivate(item: IJobstProduct): Observable<any> {

        return this.delete('/jobst/' + item.objectId);
    }

    public getProductsBySite(siteId: string): Observable<IJobstProduct[]> {
        return this.getJson('/jobst/' + siteId + '/14');  // 14 is the product type for Jobst
    }

    add(item: IJobstProduct): Observable<IJobstProduct> {
        if (item.objectId) {

            return this.putJson('/jobst/' + item.objectId, item);
        } else {

            return this.postJson('/jobst/' + this._selectedSiteId + '/14', item).pipe(
                map((response: IJobstProduct) => {
                    item.objectId = response.objectId;

                    return response;
                })
            );
        }
    }

    protected getBaseUrl() {

        return (
            this.environment.api.HmsProductUploader[
            this.liveStageService.get('HmsProductUploader')
            ] + 'productImporter'
        );
    }
}
