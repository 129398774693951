
import { of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { sortBy } from 'lodash';
import { Observable, Subscription } from 'rxjs';

import { AdminTitle } from '../layout/AdminTitle';
import { ISite } from '../model/site/ISite';
import { SiteApi } from '../api/auth/SiteApi';
import { IProductSheet } from '../model/productImporter/IProductSheet';
import { LiveStageService } from '../environment/LiveStage.service';
import { ProductImporterApi } from '../api/productImporter/ProductImporterApi';
import { FemcareSiteApi } from '../api/product/femcare/FemcareSiteApi';
import { IFemcareSite } from '../model/product/femcare/IFemcareSite';

@Component({
    templateUrl: 'ProductImporter.component.html',
    providers: [SiteApi, ProductImporterApi, FemcareSiteApi]
})
export class ProductImporterComponent implements OnDestroy, OnInit {
    @ViewChild('browse', {static: false}) fileUpload: ElementRef;
    sites: Observable<ISite[]>;
    femcareSites:IFemcareSite[];
    items?: Observable<any>;
    selectedSiteId: string;
    deleting: Boolean = false;
    uploadInProgress: Boolean = false;
    file: any;
    fileType: string;
    uploadSuccessful: string;
    private liveStageSubscription: Subscription;

    constructor(
        private adminTitle: AdminTitle,
        private liveStageService: LiveStageService,
        private siteApi: SiteApi,
        private productImporterApi: ProductImporterApi,
        private femcareSiteApi: FemcareSiteApi
    ) {}

    ngOnInit() {
        this.adminTitle.setTitle('Product Importer');

        this.liveStageService.addListener(this, 'productImporter');
        this.femcareSiteApi.getAll().subscribe(sites => {
            this.femcareSites = sites;
        });
        this.liveStageSubscription = this.liveStageService.liveStage.subscribe(() => {
             // @ts-ignore @todo
            this.sites = this.siteApi
                .getSitesByBrand('femcare').pipe(
                // @ts-ignore @todo
                map(sites => sortBy(sites, 'name')),
                shareReplay(),);
            this.sites.subscribe(sites => {
                const filteredSites = sites.filter(site =>
                    this.femcareSites.some(femcareSite => femcareSite.siteUrl === site.domainUrl)
                );
                this.sites = of(filteredSites);             
            });
            this.selectedSiteId = undefined;
        });
    }

    ngOnDestroy() {
        this.liveStageService.removeListener(this);
        this.liveStageSubscription.unsubscribe();
    }

    getItems() {
        this.items = undefined;

        if (this.selectedSiteId) {
            this.items = this.productImporterApi
                .getItems(this.selectedSiteId).pipe(
                shareReplay());
        }
    }

    public delete(productSheet: IProductSheet) {
        this.deleting = true;
        this.productImporterApi
            .inactivate(productSheet)
            .subscribe((res: IProductSheet) => {
                this.getItems();
                this.deleting = false;
            });
    }

    browseFile() {
        let event = new MouseEvent('click');
        this.fileUpload.nativeElement.dispatchEvent(event);
    }

    browseFileInputChange() {
        if (this.fileUpload.nativeElement.files.length > 0) {
            this.file = this.fileUpload.nativeElement.files[0];
            if (
                this.file.type ===
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                this.file.type === ''
            ) {
                this.fileType = this.file.type === '' ? 'unknown' : this.file.type;
            }
        }
    }

    importFile() {
        this.uploadInProgress = true;
        let data = new FormData();
        data.append('file', this.file, this.file.name);
        this.productImporterApi.importData(data, this.selectedSiteId).subscribe(
            (res: any) => {
                this.uploadSuccessful = res;
                this.getItems();

                setTimeout(() => {
                    this.uploadSuccessful = '';
                }, 4000);
            },
            error => {
                this.uploadInProgress = false;
                this.file = null;
                this.fileType = '';
            },
            () => {
                this.uploadInProgress = false;
                this.file = null;
                this.fileType = '';
            }
        );
    }

    download(item: IProductSheet) {
        this.productImporterApi.download(item).subscribe(res => {
            let fileData = atob(res.text());
            var byteNumbers = Array(fileData.length);

            for (var i = 0; i < fileData.length; i++) {
                byteNumbers[i] = fileData.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            let blob = new Blob([byteArray], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            var objectUrl = URL.createObjectURL(blob);
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

            a.href = objectUrl;
            a.download = item.name;
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);

            URL.revokeObjectURL(objectUrl);
        });
    }

    confirm(item: any) {
        item.confirmDelete = true;
    }

    cancel(item: any) {
        item.confirmDelete = false;
    }
}
