import { PrettySubmitGuard } from 'tridion.web.ui/src/app/angular/save/PrettySubmitGuard';
import { Route } from '@angular/router';
import { ActiveDirectoryGroupsComponent } from './auth/group/ActiveDirectoryGroups.component';
import { AdminAuthGuard } from './auth/login/AdminAuthGuard';
import { AuthEntitiesComponent } from './auth/entity/AuthEntities.component';
import { AuthModulesComponent } from './auth/module/AuthModules.component';
import { Brand } from './model/auth/login/Brand';
import { DashboardComponent } from './layout/Dashboard.component';
import { DistributorFinderComponent } from './distributorFinder/DistributorFinder.component';
import { DistributionHubChildRoutes } from './routes/product/DistributionHubChildRoutes';
import { CountriesComponent } from './distributorFinder/Countries.component';
import { INavbarRoute } from './layout/navbar/INavbarRoute';
import { LogoutComponent } from './auth/login/Logout.component';
import { RedirectsComponent } from './site/redirect/Redirects.component';
import { RedisComponent } from './cache/Redis.component';
import { SitesComponent } from './site/Sites.component';
import { Tool } from './model/auth/login/Tool';
import { TorkChildRoutes } from './routes/product/TorkChildRoutes';
import { ReportRoutes } from './report/ReportRouting.module';
import { FemCareChildRoutes } from './routes/product/FemCareChildRoutes';
import { TenaChildRoutes } from './routes/product/TenaChildRoutes';
import { CampaignRoutes } from './campaign/CampaignRouting.module';
import { TpwChildRoutes } from './routes/product/TpwChildRoutes';
import { LeukoplastChildRoutes } from './routes/product/LeukoplastChildRoutes';
import { CutimedChildRoutes } from './routes/product/CutimedChildRoutes';
import { ActimoveChildRoutes } from './routes/product/ActimoveChildRoutes';
import { JobstChildRoutes } from './routes/product/JobstChildRoutes';

/* tslint:disable:max-file-line-count */

export const Routes: (INavbarRoute | Route)[] = [
    {
        path: '',
        component: DashboardComponent,
        canActivate: [AdminAuthGuard]
    },
    {
        navbarName: 'Cache',
        path: 'cache',
        component: RedisComponent,
        canActivate: [AdminAuthGuard],
        requiredAccess: {
            tool: [Tool.RedisCache]
        }
    },
    {
        navbarName: 'Distributor Finder',
        path: 'distributor-finder',
        canActivate: [AdminAuthGuard],
        canActivateChild: [AdminAuthGuard],
        requiredAccess: {
            tool: [Tool.DistributorFinder]
        },
        children: [
            {
                navbarName: 'Distributors',
                path: 'distributors',
                component: DistributorFinderComponent,
                helpUrl:
                    'https://scadev.atlassian.net/wiki/spaces/TOL/pages/986055099/Distributor+Finder+-+Activating+and+using+the+new+Distributor+Admin+tool',

                requiredAccess: {
                    tool: [Tool.DistributorFinder]
                }
            },
            {
                navbarName: 'Countries',
                path: 'countries',
                component: CountriesComponent,
                requiredAccess: {
                    tool: [Tool.DistributorFinder]
                }
            }
        ]
    },
    {
        navbarName: 'Products',
        path: 'products',
        canActivate: [AdminAuthGuard],
        canActivateChild: [AdminAuthGuard],
        requiredAccess: {
            brand: [Brand.FemCare, Brand.Tena, Brand.Tork]
        },
        children: [
            {
                navbarName: 'FemCare',
                path: 'femcare',
                requiredAccess: {
                    brand: [Brand.FemCare]
                },
                children: [...FemCareChildRoutes]
            },
            {
                navbarName: 'TCW',
                path: 'tena',
                requiredAccess: {
                    brand: [Brand.Tena]
                },
                children: [...TenaChildRoutes]
            },
            {
                navbarName: 'Leukoplast',
                path: 'leukoplast',
                requiredAccess: {
                    brand: [Brand.Tena]
                },
                children: [...LeukoplastChildRoutes]
            },
            {
                navbarName: 'Cutimed',
                path: 'cutimed',
                requiredAccess: {
                    brand: [Brand.Tena]
                },
                children: [...CutimedChildRoutes]
            },
            {
                navbarName: 'Actimove',
                path: 'actimove',
                requiredAccess: {
                    brand: [Brand.Tena]
                },
                children: [...ActimoveChildRoutes]
            },
            {
                navbarName: 'JOBST',
                path: 'jobst',
                requiredAccess: {
                    brand: [Brand.Tena]
                },
                children: [...JobstChildRoutes]
            },
            {
                navbarName: 'TPW',
                path: 'tpw',
                requiredAccess: {
                    brand: [Brand.Tena]
                },
                children: [...TpwChildRoutes]
            },
            {
                navbarName: 'Tork',
                path: 'tork',
                requiredAccess: {
                    brand: [Brand.Tork]
                },
                children: [...TorkChildRoutes]
            },
            {
                navbarName: 'Distribution Hub',
                path: 'distribution-hub',
                requiredAccess: {
                    brand: [Brand.DistributionHub]
                },
                children: [...DistributionHubChildRoutes]
            }
        ]
    },
    {
        navbarName: 'Redirects',
        path: 'redirects',
        component: RedirectsComponent,
        canActivate: [AdminAuthGuard],
        canDeactivate: [PrettySubmitGuard],
        helpUrl:
            'https://scadev.atlassian.net/wiki/spaces/TP/pages/107555591/4.+SEO+Redirect+How+To+s',
        requiredAccess: {
            tool: [Tool.Redirects]
        }
    },
    {
        navbarName: 'Security',
        path: 'security',
        canActivate: [AdminAuthGuard],
        canActivateChild: [AdminAuthGuard],
        requiredAccess: {
            tool: [Tool.Security]
        },
        children: [
            {
                navbarName: 'Access Levels',
                path: 'access-levels',
                component: AuthEntitiesComponent,
                canDeactivate: [PrettySubmitGuard],
                helpUrl:
                    'https://scadev.atlassian.net/wiki/spaces/TP/pages/105335107/7.1.1+Access+Levels'
            },
            {
                navbarName: 'Groups',
                path: 'groups',
                component: ActiveDirectoryGroupsComponent,
                canDeactivate: [PrettySubmitGuard],
                helpUrl:
                    'https://scadev.atlassian.net/wiki/spaces/TP/pages/105352840/7.1.3+Groups'
            },
            {
                navbarName: 'Tools',
                path: 'tools',
                component: AuthModulesComponent,
                canDeactivate: [PrettySubmitGuard],
                helpUrl:
                    'https://scadev.atlassian.net/wiki/spaces/TP/pages/105341100/7.1.2+Tools'
            }
        ]
    },
    {
        navbarName: 'Sites',
        path: 'sites',
        component: SitesComponent,
        canActivate: [AdminAuthGuard],
        canDeactivate: [PrettySubmitGuard]
    },
    {
        navbarName: 'Reports',
        path: 'reports',
        canActivate: [AdminAuthGuard],
        canActivateChild: [AdminAuthGuard],
        requiredAccess: {
            tool: [Tool.Reports]
        },
        children: [...ReportRoutes]
    },
    {
        navbarName: 'Campaign',
        path: 'campaign',
        canActivate: [AdminAuthGuard],
        canActivateChild: [AdminAuthGuard],
        children: [...CampaignRoutes]
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: '**',
        redirectTo: '/'
    }
];
