import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { JobstService } from './Jobst.service';
import { JobstWebShopCatalogApi } from '../../../api/product/tena/jobst/JobstWebShopCatalogApi';
import { IJobstWebShopCatalog } from '../../../model/product/tena/jobst/IJobstWebShopCatalog';

@Component({
    selector: 'admin-jobst-country-select',
    templateUrl: 'JobstCountrySelect.component.html',
    providers: [JobstWebShopCatalogApi]
})
export class JobstCountrySelectComponent implements OnInit, OnDestroy {
    countryForm: FormGroup;
    countries: IJobstWebShopCatalog[];

    @Input()
    selectedCountry: IJobstWebShopCatalog;

    @Input()
    modalVisible: boolean;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public jobstService: JobstService,
        private jobstCatalogApi: JobstWebShopCatalogApi,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.jobstService.countries.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((countries: IJobstWebShopCatalog[]) => {
                this.countries = countries;
            });

        this.countryForm = this.fb.group({
            country: new FormControl(
                this.selectedCountry ? this.selectedCountry.objectId.toString() : '',
                Validators.required
            )
        });

        this.jobstCatalogApi.getAll().subscribe((res: IJobstWebShopCatalog[]) => {
            this.jobstService.countries.next(res);
        });

        this.jobstService.countryModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.toggleCountryModal(visible);
            });

        this.toggleCountryModal(this.modalVisible);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    selectCountry(country: IJobstWebShopCatalog) {
        if (this.countryForm.valid) {
            country = this.countries.find((item: IJobstWebShopCatalog) => {
                if (item.objectId === parseInt(this.countryForm.value.country)) {
                    return true;
                }
            });
            window.sessionStorage.setItem('jobstSelectedCountry', JSON.stringify(country));
            this.jobstService.countryChanged.next(country);

            this.jobstService.countryModalVisible.next(false);
        }
    }

    toggleCountryModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }
    }
}
