import {takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { IJobstSegment } from '../../../model/product/tena/jobst/IJobstSegment';
import { JobstService } from './Jobst.service';
import { JobstUserSegmentApi } from '../../../api/product/tena/jobst/JobstUserSegmentApi';

@Component({
    selector: 'admin-jobst-segment-select',
    templateUrl: 'JobstSegmentSelect.component.html',
    providers: [JobstUserSegmentApi]
})
export class JobstSegmentSelectComponent implements OnInit, OnDestroy {
    segmentForm: FormGroup;
    segments: IJobstSegment[];

    @Input()
    selectedSegment: IJobstSegment;

    @Input()
    modalVisible: boolean;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public jobstService: JobstService,
        private jobstSegmentApi: JobstUserSegmentApi,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.jobstService.segments.pipe(
            takeUntil(this.unsubscribe))
            .subscribe((segments: IJobstSegment[]) => {
                this.segments = segments;
            });

        this.segmentForm = this.fb.group({
            segment: new FormControl(
                this.selectedSegment ? this.selectedSegment.objectId.toString() : '',
                Validators.required
            )
        });

        this.jobstSegmentApi.getAll().subscribe((res: IJobstSegment[]) => {
            let JobstSegment = res.filter(segment => segment.isJobstSegment);
            this.jobstService.segments.next(JobstSegment);
        });

        this.jobstService.segmentModalVisible.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(visible => {
                this.toggleSegmentModal(visible);
            });

        this.toggleSegmentModal(this.modalVisible);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    selectSegment(segment: IJobstSegment) {
        if (this.segmentForm.valid) {
            segment = this.segments.find((item: IJobstSegment) => {
                if (item.objectId === parseInt(this.segmentForm.value.segment)) {
                    return true;
                }
            });
            window.sessionStorage.setItem('jobstSelectedSegment', JSON.stringify(segment));
            this.jobstService.segmentChanged.next(segment);

            this.jobstService.segmentModalVisible.next(false);
        }
    }

    toggleSegmentModal(show: boolean) {
        if (show) {
            document.getElementsByTagName('body')[0].className = 'modal-open';
        } else {
            document.getElementsByTagName('body')[0].classList.remove('modal-open');
        }
    }
}
